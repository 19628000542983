
<template>
   <div>
      <order-list-table :orderList="orderList" />
  </div>
</template>

<script>

import OrderListTable from "./components/OrderListTable.vue"
// Cell Renderer
export default {
  name:"order-list",
  components: {
    OrderListTable,
  },
  computed:{
    orderList() {
      return JSON.parse(JSON.stringify(this.$store.state.orderList.orders))
    },
  },
  mounted() {
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
}
</script>


